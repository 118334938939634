var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-scheduler',{attrs:{"events":_vm.events,"event-display":_vm.eventDisplay,"labels":{
      today: 'Aujourd\'hui',
      back: 'Précédent',
      next: 'Suivant',
      month: 'Mois',
      week: 'Semaine',
      day: 'jour',
      all_day: 'heure',
    },"overrideCheckIfCanClickDay":_vm.getSiDateEstDispo,"available-views":['month'],"disableDialog":true},on:{"event-clicked":_vm.eventClicked,"day-clicked":_vm.dayClicked,"month-changed":_vm.monthChanged}}),_c('b-modal',{attrs:{"id":"modal-rv","centered":"","hide-footer":"","title":"Rendez-vous"}},[_c('ReservationRv',{attrs:{"dateChoisi":_vm.dateChoisi,"dispos":_vm.disposChoisi,"professionnel":_vm.professionnel}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }