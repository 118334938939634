import axios from 'axios'

const state = {
}

const getters = {
}

const actions = {
  sauvegarderNouveauSpecialite ({ commit }, body) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData()
      var x
      for (x in body) {
        bodyFormData.append(x, body[x])
      }
      axios({
        url: '/add_specialite',
        method: 'post',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: bodyFormData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  sauvegarderSpecialiteExistant ({ commit }, body) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData()
      var x
      for (x in body) {
        bodyFormData.append(x, body[x])
      }
      axios({
        url: '/update_specialite',
        method: 'post',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: bodyFormData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  supprimerSpecialite ({ commit }, body) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData()
      var x
      for (x in body) {
        bodyFormData.append(x, body[x])
      }
      axios({
        url: '/delete_specialite',
        method: 'post',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: bodyFormData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
