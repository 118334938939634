<template>
  <b-navbar toggleable="lg" type="light" variant="white">
    <b-navbar-toggle id="nav-toggle" target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item v-if="isLoggedIn" href="/">Répertoire</b-nav-item>
        <b-nav-item href="https://dev9.cwmserver.com/tarifs/">Tarifs</b-nav-item>
        <b-nav-item href="https://dev9.cwmserver.com/blogue/">Blogue</b-nav-item>

        <b-nav-item-dropdown text="Forum" right>
          <b-dropdown-item href="https://dev9.cwmserver.com/forum/">Le forum</b-dropdown-item>
          <b-dropdown-item href="https://dev9.cwmserver.com/index.php/forum/activity/">Activité</b-dropdown-item>
          <b-dropdown-item href="https://dev9.cwmserver.com/guide-dutilisation-du-forum/">Guide d'utilisation du forum</b-dropdown-item>
          <b-dropdown-item href="https://dev9.cwmserver.com/regles-dutilisations-du-forum/">Règles d'utilisation du forum</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="FAQ" right>
          <b-dropdown-item href="https://dev9.cwmserver.com/faq/">La FAQ</b-dropdown-item>
          <b-dropdown-item href="https://dev9.cwmserver.com/question-2/">Poser une question</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item href="https://dev9.cwmserver.com/contact/">Contact</b-nav-item>
        <b-nav-item href="https://dev9.cwmserver.com/devenir-professionnel-chez-pro-ressources/">Rejoindre l'équipe</b-nav-item>
        <b-button size="md" class="button-menu btnHoverBleu" v-if="isLoggedIn" @click="profil()"><img width="30" height="30" src="/calendar_inv.png"></b-button>
        <b-button size="md" class="button-menu btnHoverBleu" v-if="isLoggedIn && isProfessionnel" @click="webex()">Webex</b-button>
        <b-button size="md" class="button-menu btnHoverBleu" v-if="isLoggedIn && isAdmin" @click="administration()">Administration</b-button>
        <b-button size="md" class="button-menu btnHoverBleu" v-if="isLoggedIn" @click="logout()">[→</b-button>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'NavigationBar',
  data () {
    return {
      isLoggedIn: localStorage.getItem('token'),
      isAdmin: parseInt(localStorage.getItem('role')) === 1,
      isProfessionnel: parseInt(localStorage.getItem('role')) === 2
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
        this.isLoggedIn = null
      })
    },
    profil () {
      history.pushState({}, document.title, window.location.href)
      this.$router.push({ name: 'Profil' })
    },
    webex () {
      history.pushState({}, document.title, window.location.href)
      window.location.href = 'https://teams.webex.com/'
    },
    administration () {
      history.pushState({}, document.title, window.location.href)
      this.$router.push({ name: 'pageAdmin' })
    }
  }
}
</script>

<style>
  nav
  {
    font-size: 18px;
    color: black;
  }

  .navbar-light .navbar-nav .nav-link
  {
    color: black !important;
    padding-right:25px !important;
    opacity: 1;
  }
    .navbar-light .navbar-nav .nav-link:hover
  {
    color: #29689c !important;
  }
  .navbar {
    z-index:300;
  }

  button{
    margin-bottom: 20px;
    background-color: #ffc90e !important;
    color: #fff;
    font-family: roboto;
    font-size: 20px;
    padding:15px;
    border-radius: 10px;
    border-color:#ffc90e;
  }

  .btnHoverBleu:hover{
    background-color: #29689c !important;
  }

  .button-menu {
    margin-right:10px !important;
  }
@media screen and (max-width: 1386px) {
  .button-menu:first {
    margin-right:0px;
  }
  #nav-toggle
  {
    margin:auto;
  }
  #logo-header
   {
    height:60px !important;
    margin-top:20px !important;
    margin-bottom:20px !important;
  }
  .navbar-nav ml-auto
  {
    margin-right:auto !important;
  }
.ml-auto, .mx-auto
{
    margin-right:auto !important;
  }
  .button-menu {
    /* width: 60%;
    margin-left:20%; */
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
