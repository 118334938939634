<template>
    <div id="container">
      <b-button @click="autorisationWebex()">Autoriser Webex se connecter a se compte</b-button><br>
      <b-button @click="adminWebex()">Accéder au paneau d'administration Webex</b-button>
      <div id = "buttonNouveauContainer"><b-button class="buttonNouveau" type="submit" @click="ajouterProfessionnel()">Ajouter Nouveau</b-button></div>
      <br>
      <table>
        <tr>
          <th>ID</th>
          <th>Prénom<th>
          <th>Nom</th>
          <th>Catégorie</th>
        </tr>
        <tr v-for="prof in listeProfessionnels" v-bind:key="prof.id">
          <td>{{ prof.id }}</td>
          <td>{{ prof.first_name }}<td>
          <td>{{ prof.last_name }}</td>
          <td>{{ prof.categoryName }}</td>
          <td><b-button class="buttonModifier" type="submit" @click="modifierProfessionnel(prof.id)">Modifier</b-button></td>
          <td><b-button @click="supprimerProfessionnel($event)">Supprimer</b-button></td>
        </tr>
      </table>
    </div>
</template>

<script>
export default {
  name: 'PageInfoProfessionnel',
  computed: {
    listeProfessionnels () {
      return this.$store.getters.listeProfessionnels
    }
  },
  methods: {
    modifierProfessionnel: function (idProfessionnel) {
      history.pushState({}, document.title, window.location.href)
      this.$router.push({ name: 'pageGestionCompteProf', params: { id: idProfessionnel } })
    },
    ajouterProfessionnel: function () {
      history.pushState({}, document.title, window.location.href)
      this.$router.push({ name: 'pageGestionCompteProf' })
    },
    supprimerProfessionnel (e) {
      this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cet utilisateur?', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        // value retourne true si le user fait Oui, false si le user fait Non et null si il fait le x ou esc
        if (value) {
          this.$store.dispatch('deleteProfessionnel', e.path[2].cells[0].innerHTML)
        }
      })
    },
    adminWebex () {
      history.pushState({}, document.title, window.location.href)
      window.location.href = 'https://admin.webex.com/'
    },
    autorisationWebex () {
      this.$store.dispatch('requestUserWebexToken')
        .then((res) => {
          var webexClientId = process.env.VUE_APP_CLIENT_ID// res.data (c'est si chaque professionnel peut séduler lui même, le code est déja là)
          // if (!webexClientId && this.$store.getters.isAdmin) {
          //   webexClientId = process.env.VUE_APP_CLIENT_ID
          // }
          if (webexClientId) {
            history.pushState({}, document.title, window.location.href)
            var CLIENT_ID = webexClientId
            var REDIRECT_URI = process.env.VUE_APP_REDIRECTION_URL
            var STATE = 'cf95707246a48ba9a304100f40c5a67a518a90b30a3ea8088f3d40e4cd2b2ed2'
            var SCOPE = process.env.VUE_APP_REDIRECTION_SCOPE
            window.location.href = 'https://webexapis.com/v1/authorize?client_id=' + CLIENT_ID + '&response_type=code&redirect_uri=' + REDIRECT_URI + '&scope=' + SCOPE + '&state=' + STATE
          }
        })
    }
  },
  created () {
    if (this.$store.getters.isLoggedIn && this.$route.query.code && this.$route.query.state) {
      this.$store.dispatch('updateToken', { code: this.$route.query.code, secret: this.$route.query.state, redirectionUrl: process.env.VUE_APP_REDIRECTION_URL })
    }
    this.$store.dispatch('fetchProfessionnels')
  }
}
</script>

<style>
  #buttonNouveauContainer{
    display: block;
  }
</style>
