<template>
  <div class="boxes">
    <div class="connection-container">
      <ConnectionWindow :recaptcha="recaptcha"></ConnectionWindow>
    </div>
    <div class="register-container">
      <RegisterClientForm :recaptcha="recaptcha"></RegisterClientForm>
    </div>
  </div>
</template>

<script>

import ConnectionWindow from '@/components/ConnectionWindow'
import RegisterClientForm from '@/components/RegisterClientForm'
import { load } from 'recaptcha-v3'

export default {
  name: 'Home',
  components: {
    ConnectionWindow,
    RegisterClientForm
  },
  data () {
    return {
      recaptcha: null
    }
  },
  created () {
    load(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY).then((recaptcha) => {
      this.recaptcha = recaptcha
    })
  }
}
</script>

<style scoped>
.boxes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width:80%;
  max-width: 75vw;
  margin-right:auto;
  margin-left: auto;
}
.connection-container {
  width: 50%;
  height:fit-content;
  margin-right:60px;
}
.register-container{
   height: fit-content;
  width: 50%;
  margin-left:60px;
}
.connection-container, .register-container {
  border: 1px solid black;
  border-radius: 3px;
  background-color: white;
}

.errorMessage {
  font-size: 14px;
  color:red;
  }
@media screen and (max-width: 1800px) {
.boxes
{
  padding-top:10vh;
  padding-bottom:10vh;
  }
.container, .register-container
{
  min-height:81vh;
  }
}
@media screen and (max-width: 1300px) {

.boxes {
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-between;
  margin-right:auto;
  padding-bottom:0px;
  padding-top:0px;
  margin-left: auto; }

.connection-container {
  width: 90%;
  height:fit-content;
  margin-right:auto;
  margin-left: auto;
  margin-bottom:50px;
  margin-top:50px;
}
.register-container{
   height: fit-content;
  width:90%;
 margin-left: auto;
 margin-right:auto;
 margin-bottom:60px;
}

}
/* @media only screen and (min-width: 500px) {
}

  @media only screen and (min-width: 600px) {
  }

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 992px) {

}
@media only screen and (min-width: 1200px) {

} */
</style>
