import Vue from 'vue'
import EventDialog from './EventDialog.vue';
import moment from 'moment'

function open(propsData) {
    var EventDialogComponent = Vue.extend(EventDialog);
    return new EventDialogComponent({
        el: document.createElement('div'),
        propsData
    });
}

export default {
    show(params, extraFields) {
        var defaultParam = {
            title: 'Create event',
            inputClass: null,
            overrideInputClass: false,
            createButtonLabel: 'Create',
            //  -------------------------
            date: null,
            startTime: null,
            endTime: null,
            enableTimeInputs: true,
            dateReadOnly: false
        };

        var propsData = Object.assign(defaultParam, params);
        var defaultFields = []
        if(propsData.dateReadOnly){
            defaultFields.push({
                name: 'date',   //  Required
                type: 'text',   //  def: 'text'
                label: 'Date',  //  def: this.name
                readonly: true,
                // showLabel: false,    //  def: true
                required: true, //  def: false,
                value: moment(propsData.date).format('YYYY-MM-DD'),   //  def: null
            })
        }
        else {defaultFields.push({
            name: 'date',   //  Required
            type: 'date',   //  def: 'text'
            label: 'Date',  //  def: this.name
            // showLabel: false,    //  def: true
            required: true, //  def: false,
            value: propsData.date,   //  def: null
        })
        }

        if ( propsData.enableTimeInputs )
            defaultFields.splice(1, 0, {
                label: 'Times',
                fields: [
                    {
                        name: 'startTime',
                        type: 'time',
                        label: 'Start Time',
                        required: true,
                        value: propsData.startTime
                    },
                    {
                        name: 'endTime',
                        type: 'time',
                        label: 'End Time',
                        required: true,
                        value: propsData.endTime
                    }
                ]
            });

        propsData.fields = extraFields ? defaultFields.concat(extraFields) : defaultFields;
        return open(propsData);
    }
}