<template>
  <div class="container">
   <form v-on:submit.prevent="soumettreProfessionnel">
    <table v-if="professionnel">
    <tr>
      <td>Prénom</td>
      <td>
        <b-form-input required type="text" name="first_name" placeholder="Prénom" v-model="professionnel.first_name" />
      </td>
    </tr>
    <tr>
      <td>Nom de famille</td>
      <td>
        <b-form-input required type="text" name="last_name" placeholder="Nom de famille" v-model="professionnel.last_name" />
      </td>
    </tr>
    <tr>
      <td>Catégorie</td>
      <td>
        <select v-model="professionnel.category">
          <option v-for="option in listeCategories" v-bind:key="option.id" v-bind:value="option.id">
            {{ option.name }}
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td>Téléphone</td>
      <td>
        <b-form-input required type="tel" name="phone" placeholder="Téléphone" v-model="professionnel.phone" />
      </td>
    </tr>
    <tr>
      <td>Email</td>
      <td>
        <b-form-input required type="email" name="email" placeholder="Email" v-model="professionnel.email" />
      </td>
    </tr>
    <tr>
      <td>Adresse</td>
      <td>
        <b-form-input required type="text" name="address" placeholder="Adresse" v-model="professionnel.address" />
      </td>
    </tr>
    <tr>
      <td>Code Postal</td>
      <td>
        <b-form-input required type="text" name="zip_code" placeholder="Code Postal" v-model="professionnel.zip_code" />
      </td>
    </tr>
    <tr>
      <td>Ville</td>
      <td>
        <b-form-input required type="text" name="city" placeholder="Ville" v-model="professionnel.city" />
      </td>
    </tr>
    <tr>
      <td>Province</td>
      <td>
        <b-form-input required type="text" name="province" placeholder="Province" v-model="professionnel.province" />
      </td>
    </tr>
    <tr>
      <td>Pays</td>
      <td>
        <b-form-input required type="text" name="country" placeholder="Pays" v-model="professionnel.country" />
      </td>
    </tr>
    <tr>
      <td>Description</td>
      <td>
        <b-form-textarea type="text" name="description" placeholder="Description" v-model="professionnel.description" />
      </td>
    </tr>
    <!-- <tr>
      <td>Tarif ($/h)</td>
      <td>
        <b-form-input required type="number" name="price_per_hour" placeholder="Tarif" v-model="professionnel.price_per_hour" />
      </td>
    </tr> -->
    <tr>
      <td>Photo</td>
      <td>
        <b-form-file type="text" name="photo" placeholder="photo" v-model="photoFile" />
      </td>
    </tr>
    <tr>
      <td>Mot de passe</td>
      <td>
        <b-form-input :required="!$route.params.id" type="password" name="password" placeholder="Mot de passe" v-model="professionnel.password" />
      </td>
    </tr>
    <tr>
      <td>Confirmation de mot de passe</td>
      <td>
        <b-form-input :required="!$route.params.id" type="password" name="c_password" placeholder="Confirmation de mot de passe" v-model="professionnel.c_password" /><br>
      </td>
    </tr>
    </table>
    <b-button class="buttonSoumettre" type="submit">Soumettre</b-button><br>
    <b-button class="buttonBack" @click="back()">Annuler</b-button><br>
    <span style ="color:red" v-for="erreur in listeErreurs" v-bind:key="erreur">{{ erreur }}<br></span>
    </form>
  </div>
</template>

<script>
export default {
  name: 'PageGestionCompteProf',
  data () {
    return {
      professionnel: {},
      photoFile: null,
      listeErreurs: [],
      retourServeur: ''
    }
  },
  computed: {
    siNouveau () {
      return this.$route.params.id == null
    },
    listeCategories () {
      return this.$store.getters.listeCategories
    },
    url () {
      return (this.$route.params.id ? '/users/' : '/registerProfessionnal')
    }
  },
  methods: {
    soumettreProfessionnel () {
      this.listeErreurs = []
      if (!this.professionnel.first_name) {
        this.listeErreurs.push('Le prénom est requis')
      }
      if (!this.professionnel.last_name) {
        this.listeErreurs.push('Le nom de famille est requis')
      }
      if (!this.professionnel.phone) {
        this.listeErreurs.push('le numéro de téléphonne est requis')
      }
      if (!this.professionnel.email) {
        this.listeErreurs.push('Le email est requis')
      }
      if (!this.professionnel.city) {
        this.listeErreurs.push('La ville est requise')
      }
      if (!this.professionnel.address) {
        this.listeErreurs.push('L\'adresse est requise')
      }
      if (!this.professionnel.country) {
        this.listeErreurs.push('Le pays est requis')
      }
      if (!this.professionnel.province) {
        this.listeErreurs.push('La province est requise')
      }
      if (!this.professionnel.password) {
        if (this.siNouveau) {
          this.listeErreurs.push('Le mot de passe est requis')
        }
      } else if (!this.professionnel.c_password) {
        if (this.siNouveau) {
          this.listeErreurs.push('La confirmation de mot de passe est requise')
        }
      } else if (this.professionnel.password !== this.professionnel.c_password) {
        if (this.siNouveau) {
          this.listeErreurs.push('La confirmation de mot de passe n\'est pas similaire au mot de passe')
        }
      }
      if (!this.professionnel.zip_code) {
        this.listeErreurs.push('Le code postal est requis')
      }
      if (!this.professionnel.category) {
        this.listeErreurs.push('La catégorie est requise')
      }
      // if (!this.professionnel.price_per_hour) {
      //   this.listeErreurs.push('Le taux horaire est requis')
      // }
      if (this.listeErreurs.length === 0) {
        this.retourServeur = this.$store.dispatch('registerProfessionnel', { professionnel: this.professionnel, photoProf: this.photoFile, url: this.url }).then((response) => {
          if (response.status === 200) {
            history.pushState({}, document.title, window.location.href)
            this.$router.push({ name: 'pageAdmin' })
          }
        }).catch((err) => {
          this.retourServeur = err
        })
        this.$store.dispatch('requestSpecificUserDetails', this.$route.params.id).then((user) => {
          this.professionnel = user['data']
        })
      }
    },
    back () {
      this.$router.push({ path: '/admin' })
    }
  },
  created () {
    if (!this.siNouveau) {
      this.$store.dispatch('requestSpecificUserDetails', this.$route.params.id).then((user) => {
        this.professionnel = user['data']
      })
    } else {
      this.professionnel = {}
    }
    this.$store.dispatch('fetchCategories')
  }
}
</script>

<style scoped>
img{
  width: 100px;
  height: 100px;
}
</style>
