<template>
    <div id ="corppage">
       <img class="banniere" src ="@/assets/banniere.jpg">
      <div class="topText">
       <h1 id="profTITLE" class="ml-5">Trouvez votre spécialiste</h1>
        <div class="left" v-if="listeCategories">
         <select class="ml-5 texteRecherche" @change="updateProfessionnelListe" v-model="listeCategoriesSelected">
          <option value ="-1"> Toutes les catégories </option>
          <option v-for="option in listeCategories" v-bind:key="option.id" v-bind:value="option.id">
            {{ option.name }}
           </option>
        </select>
        </div>
        <div class="ml-5 left texteRecherche">Rechercher dans la liste<input type="text" class="rechercheInput" v-model="professionnelCherche" ><button class="rechercheBtn" @click="updateProfessionnelListe">Chercher</button></div>
      </div>
      <table id ="listeFiches">
      <FicheProfessionnel v-for="prof in listeProfessionnelAffiche" :key="prof.id" v-bind:professionnel="prof" />
      </table>
      <b-modal id="modal-dispo" size="xl" centered hide-footer title="Disponibilités">
        <RendezVousPopup v-bind:professionnel="professionnelRv" />
      </b-modal>
    </div>
</template>

<script>
import RendezVousPopup from '@/components/RendezVousPopup'
import FicheProfessionnel from '@/components/FicheProfessionnel'
export default {
  name: 'ListeProfessionnels',
  components: {
    FicheProfessionnel,
    RendezVousPopup
  },
  data () {
    return {
      listeCategoriesSelected: '-1',
      professionnelRv: -1,
      siAfficheNonWebex: false,
      professionnel_tarif: null,
      professionnelCherche: '',
      listeProfessionnelAffiche: []
    }
  },
  computed: {
    listeCategories () {
      return this.$store.getters.listeCategories.filter(cat => this.$store.getters.listeProfessionnels.find(prof => parseInt(prof.category) === parseInt(cat.id)) !== null)
    }
  },
  methods: {
    toggleRendezVousModal (professionnel) {
      this.professionnelRv = professionnel
      this.$bvModal.show('modal-dispo')
    },
    chercheTexteDansProfessionnel (professionnel, texte) {
      if (this.chercheSiUnChampStringContienUnAutre(professionnel.first_name, texte)) {
        return true
      }
      if (this.chercheSiUnChampStringContienUnAutre(professionnel.last_name, texte)) {
        return true
      }
      if (this.chercheSiUnChampStringContienUnAutre(professionnel.description, texte)) {
        return true
      }
      var siTrouve = false
      professionnel.specialites.forEach(spec => {
        if (this.chercheSiUnChampStringContienUnAutre(spec.nom, texte)) {
          siTrouve = true
        }
      })
      return siTrouve
    },
    chercheSiUnChampStringContienUnAutre (champ1, champ2) {
      return this.removeAccents(champ1.toLowerCase()).includes(this.removeAccents(champ2.toLowerCase())) || this.removeAccents(champ2.toLowerCase()).includes(this.removeAccents(champ1.toLowerCase()))
    },
    removeAccents (str) {
      var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
      var accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
      str = str.split('')
      var strLen = str.length
      var i, x
      for (i = 0; i < strLen; i++) {
        if ((x = accents.indexOf(str[i])) !== -1) {
          str[i] = accentsOut[x]
        }
      }
      return str.join('')
    },
    updateProfessionnelListe () {
      var professionnels = this.$store.getters.listeProfessionnels
      this.listeProfessionnelAffiche = []
      professionnels.forEach(prof => {
        if (parseInt(this.listeCategoriesSelected) === -1 || parseInt(this.listeCategoriesSelected) === prof.category) {
          if (this.chercheTexteDansProfessionnel(prof, this.professionnelCherche)) {
            this.listeProfessionnelAffiche.push(prof)
          }
        }
      })
    }
  },
  created () {
    var premierDejaAcompli = false
    this.$store.dispatch('fetchProfessionnels').then(() => {
      if (premierDejaAcompli) {
        this.updateProfessionnelListe()
      } else {
        premierDejaAcompli = true
      }
    })
    this.$store.dispatch('fetchCategories').then(() => {
      if (premierDejaAcompli) {
        this.updateProfessionnelListe()
      } else {
        premierDejaAcompli = true
      }
    })
  }
}
</script>

<style>
#listeFiches{
  display: inline-block;
  width:90%;
  margin-top:30px;
  margin-right:auto;
  margin-left:auto;
}
#profTITLE{
  text-align:left !important;
}
#corp
#corppage{
  width:80%;
  margin-right:auto;
  margin-left:auto;
  background-color:white;
}
#corppage h1
{
  font-size:36px;
  color:#235c8f;
  text-align:left;
  font-family:Roboto;
  font-weight:900;
  margin-bottom:20px;
}
#corppage p
{
  margin-left:10vw;
  text-align:left;
}
.rechercheInput {
  margin-left: 5px;
  margin-right: 5px;
}
.topText{
    margin-left:10vw;
    margin-bottom:80px;
}

.left {
  float: left;
  text-align:left;
  margin-bottom: 10px;
  display: inline;
}

.texteRecherche
{
  border-radius:5px;
  border:0px;
  outline:0px;
  color:#235c8f;
  font-family:roboto;
  font-weight:bold;
  text-align:left;
  font-size:12px;
}
.rechercheBtn {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
#filtreCategorie
{
  display:flex;
  margin-left:10vw;
  margin-bottom:50px;
  width:50%;
}
.banniere
{
  width:calc(100% - 20vw);
  margin-right:10vw;
  margin-left:10vw;
  margin-top:20px;
  margin-bottom:50px;
}
@media only screen and (max-width: 1200px) {

.banniere
{
  height:10vh;
}

}
@media only screen and (min-width: 600px) {

  .texteRecherche{
    font-size:14px;
  }

  #listeFiches{
width:70%;
  }

  #corppage h1
  {
    font-size:48px;
  }
}

@media only screen and (min-width: 768px) {
  #listeFiches{
width:50%;
  }
}
</style>
