<template>
<div>
    <form id="payment-form" class="mt-5">
      <div class="resumeAchats">
        <h2>Résumé de la transaction</h2>
        <table>
          <tr><td>Rendez-vous avec: </td><td>{{ fullProfessionnelName }}</td></tr>
          <tr><td>Tarif du professionnel: </td><td>{{ infoTransaction.professionnel.categoryTarif }} $ par 20 minutes</td></tr>
          <tr><td>Longueur du Rendez-vous: </td><td>{{ infoTransaction.longueursRv }} minutes</td></tr>
          <tr><td>Total: </td><td>{{ getPrix }}</td></tr>
        </table>
      </div>
        <div class="form-row">
            <label for="card-element">
                <h3>Crédit ou débit</h3>
            </label>
            <div ref="card" id="card-element">
            <!-- A Stripe Element will be inserted here. -->
            </div>

            <!-- Used to display form errors. -->
            <div id="card-errors" role="alert"></div>
        </div>
        <button id="btnSubmitPayment" type="submit">Confirmer la réservation</button>
    </form>
    <b-modal id="modal-chargement" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered hide-footer title="Chargement">
      Chargement...
    </b-modal>
    <b-modal id="modal-fini" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered hide-footer title="Succès">
      Votre transation a été effectuée avec succès!<br>  <b-button class="btnLike" href="/profil">RETOUR À MES RENDEZ-VOUS</b-button>
    </b-modal>
    <b-modal id="modal-nonDispo" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered hide-footer title="Succès">
      La plage horaire que vous avez sélectionné n'est plus disponible! <br>  <b-button class="btnLike" href="/professionnels">RETOUR AU REGISTRE</b-button>
    </b-modal>
    <b-modal id="modal-rvDejaLa" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered hide-footer title="Succès">
      Vous avez déjà un rendez-vous dans cette plage horaire! <br>  <b-button class="btnLike" href="/professionnels">RETOUR AU REGISTRE</b-button>
    </b-modal>
</div>
</template>

<script>
var stripe = window.Stripe('pk_test_51H9ak7HHe80yKJCuHiHN8BrFeYJcEwh5d99IsXkMyncYtAnxVWMTsIuXTzJwuofapVoBXSjrlH2U8TGQ3kYgRwl300zhVBEqzA')
var elements = stripe.elements()
var card
var style = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
}
export default {
  name: 'Payment',
  props: ['infoTransaction'],
  computed: {
    fullProfessionnelName () {
      return this.infoTransaction.professionnel.first_name + ' ' + this.infoTransaction.professionnel.last_name
    },
    getPrix () {
      var tarifCategorie = parseFloat(this.infoTransaction.professionnel.categoryTarif).toFixed(2)
      var tempsTotal = parseFloat(this.infoTransaction.longueursRv).toFixed(2)
      var nbDe20Min = parseFloat(tempsTotal / 20).toFixed(2)
      var total = parseFloat(tarifCategorie * nbDe20Min).toFixed(2)
      return total + '$'
    }
  },
  methods: {
    submitForm (event) {
      event.preventDefault()
      stripe.createToken(card).then((result) => {
        if (result.error) {
          // Inform the user if there was an error.
          var errorElement = document.getElementById('card-errors')
          errorElement.textContent = result.error.message
        } else {
          this.$bvModal.show('modal-chargement')
          const formData = new FormData()
          formData.append('professionnel_id', this.infoTransaction.professionnel.id)
          formData.append('datetime_start', this.infoTransaction.datetime_start)
          formData.append('datetime_end', this.infoTransaction.datetime_end)
          formData.append('stripe_token', result.token.id)
          var object = {}
          formData.forEach((value, key) => { object[key] = value })
          var json = object
          this.$store.dispatch('addReservation', json).then(response => {
            if (response.status === 200) {
              this.$bvModal.hide('modal-chargement')
              this.$bvModal.show('modal-fini')
            } else {
              var errorElement = document.getElementById('card-errors')
              errorElement.textContent = 'Une erreur s\'est produite, veuillez communiquer avec les administrateurs du site.'
            }
          }).catch((error) => {
            console.log(error.response.data)
            this.$bvModal.hide('modal-chargement')
            if (error.response.data === 'non disponible') {
              this.$bvModal.show('modal-nonDispo')
            } else if (error.response.data === 'rv client bloque') {
              this.$bvModal.show('modal-rvDejaLa')
            } else if (error.response.data === 'card error') {
              this.$toast.error('Il y a eu une erreur avec votre carte', { duration: 5000 })
            } else {
              this.$toast.error('Une erreur est survenue, veuillez contacter les administrateurs', { duration: 5000 })
            }
          })
        }
      })
    }
  },
  mounted () {
    card = elements.create('card', { style: style })
    card.mount('#card-element')

    card.on('change', function (event) {
      var displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })

    var form = document.getElementById('payment-form')
    form.addEventListener('submit', (event) => this.submitForm(event))
  }
}
</script>

<style scoped>
.btnLike{
  font-family: 'Open Sans' !important;
  background-color: #ffc90e !important;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%) !important;
  color: white !important;
  font-weight: 700 !important;
  border-radius: 6px !important;
  transition: all .3s !important;
  border: none !important;
  text-transform: uppercase !important;
}
label
{
    display: block;
    width: 100%;
    text-align: center;
}
#btnSubmitPayment
{
    background-color:#ffc90e !important;
    border-radius:5px;
    border-color:black;
    border: black solid 1px;
}
#btnSubmitPayment:hover
{
    background-color:#29689c !important;

}
.resumeAchats h2
{
    color: #235c8f;
    text-align: left;
    font-family: Roboto;
    font-weight: 900;
    margin-bottom: 20px;

}

.resumeAchats {
  border: 1px solid black;
  padding:1vw;
  background-color:white;
  display: inline-block;
}
.resumeAchats table {
  text-align: left;
}
.resumeAchats table td {
  padding-right: 20px;
}
.StripeElement {
  box-sizing: border-box;

  height: 40px;
  padding-right:20px;
  padding-lefT:20px;
  margin:auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 500px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
@media screen and (max-width: 1200px) {
.StripeElement {
  width:80%;
  }
.resumeAchats{
  width:95%;
  margin:auto;
  }
}
</style>
