<template>
  <header>
    <section id="header-section-1">
        <div id="section-info-header">
          <a href="tel:4188966667">
            <i class="fa fa-phone" aria-hidden="true"></i>
            +1 (418) 896-6667</a>
          <a href="mailto:proressources@tutamail.com"> <i class="fa fa-envelope" aria-hidden="true"></i>
          proressource@tutamail.com</a>
        </div>
        <div id="section-button-header">
            <a ref="profilLink" @mouseover="profilMouseOver" @mouseout="profilMouseOut" href="/modifProfil">
              <img border="0" alt="userAccont" src="@/assets/user-alt.png">
            </a>
            <a href="#">
                <img border="0" alt="linkedin icon" src="@/assets/linkedIn.png">
            </a>
            <a href="#">
              <img border="0" alt="instagram icon" src="@/assets/instagram.png">
            </a>
            <a href="#">
                <img border="0" alt="facebook icon" src="@/assets/facebookIcon.png">
            </a>
        </div>
    </section>
    <section id="header-section-2">
      <a class="logo-header-container" href="https://dev9.cwmserver.com/"><img id="logo-header" src = "@/assets/logo-header.png"></a>
      <div id="nav-div">
        <navigation-bar></navigation-bar>
      </div>
    </section>
    <div ref="profilInfoContainer" :style="{ top: profilInfoPosition.y +'px', left: profilInfoPosition.x+'px'}" class="profilInfoContainer">Mon Profil</div>
  </header>
</template>

<script>
import NavigationBar from './NavigationBar.vue'
export default {
  name: 'HeaderV2',
  components: {
    NavigationBar
  },
  data () {
    return {
      profilInfoPosition: {
        x: 0,
        y: 0
      }
    }
  },
  methods: {
    profilMouseOver () {
      if (!this.$refs.profilInfoContainer.classList.contains('active')) {
        this.$refs.profilInfoContainer.classList.add('active')
        var lienRectangle = this.$refs.profilLink.getBoundingClientRect()
        var bulleRectangle = this.$refs.profilInfoContainer.getBoundingClientRect()
        // La position est ici
        this.profilInfoPosition.x = lienRectangle.left - (bulleRectangle.width - lienRectangle.width) / 2
        this.profilInfoPosition.y = lienRectangle.bottom + 10
      }
    },
    profilMouseOut () {
      if (this.$refs.profilInfoContainer.classList.contains('active')) {
        // Mettre en commentaire cette ligne là pour tester
        this.$refs.profilInfoContainer.classList.remove('active')
      }
    }
  }
}
</script>

<style scoped>
.profilInfoContainer {
  position: absolute;
  display: none;
  padding: 10px;
  border-radius: 10px;
  z-index: 300;
  border: 1px solid black;
  background-color: #29689c;
}
.active {
  display: block;
}
header
{
  color: white;
  font-size: 15px;
}
header a
{
  color: white;
  text-decoration: none;
}

header a:hover
{
  color: #ffc90e;
}

#header-section-1
{
  width: 100%;
  height: 55px;
  background-color: #29689c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 8vw;
  padding-right: 8vw;
}
#section-info-header
{
  width: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.fa
{
  margin-right: 0.5vw;
}
.fa-phone
{
  transform: rotate(260deg) scale(1.5);
}
.fa-envelope
{
  transform: scale(1.2);
}
#section-button-header
{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  width: 180px;
}
#section-button-header img
{
  height: 18px;
}

#header-section-2
{
  width: 100%;
  display: flex;
  height: 100px;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
#logo-header
{
  height: 80px;
}

@media screen and (max-width: 1202px) {

header
{
  color: white;
  font-size: 12px;
}
#section-info-header {
    width: 200px;
    flex-direction: column;
}

#header-section-1
{
  height:auto;
  padding-left: 2vw;
  padding-right: 2vw;
}
  #header-section-2
  {
    display: block;
    height: auto;
  }
}
</style>
