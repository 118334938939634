<template>
  <div id="app">
    <HeaderV2></HeaderV2>
    <router-view class="content"/>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderV2 from '@/views/HeaderV2'
import Footer from '@/views/Footer'
export default {
  name: 'App',
  components: {
    HeaderV2,
    Footer
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
html, body {
  width: 100%;
  height: 100%;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color:#E9F0F5;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

}
  button {
    font-family: 'Open Sans' !important;
    background-color: #ffc90e !important;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%) !important;
    color:white !important;
    font-weight: 700 !important;
    border-radius: 6px !important;
    transition: all .3s !important;
    border: none !important;
    text-transform: uppercase !important;
  }
   h1 {
    color:black !important;
    font-size: 37px !important;
    text-align:center !important;
    text-transform: uppercase !important;
  }
    h1, h2, h3, h4, h5
  {
    font-family: 'Open Sans' !important;
  }
  p
  {
    font-family: 'Roboto' !important;
  }
  #modal-connection {
    background-color: #29689c !important;
  }

.header, .content, .footer {
  flex-shrink: 0;
}

.content {
  flex-grow: 1;
}
@media screen and (max-width: 986px) {
  h1{
    font-size:25px !important;
  }

}
</style>
