import axios from 'axios'

const state = {
  professionnels: [],
  categories: []
}

const getters = {
  listeProfessionnels: (state) => {
    return state.professionnels
  },
  professionnel: (state) => (id) => {
    var profTemp = state.professionnels.filter(prof => parseInt(prof.id) === parseInt(id))
    if (profTemp.length === 1) {
      return profTemp[0]
    } else {
      return null
    }
  },
  listeCategories: (state) => {
    return state.categories
  }
}

const actions = {
  async fetchProfessionnels ({ commit }) {
    const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/listeProfessionnels')
    commit('setListeProfessionnels', response.data)
  },
  async fetchCategories ({ commit }) {
    const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/categories')
    commit('setListeCategories', response.data)
  },
  registerProfessionnel ({ commit }, args) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData()
      var x
      for (x in args.professionnel) {
        bodyFormData.append(x, args.professionnel[x])
      }
      if (args.photoProf) {
        bodyFormData.append('photo', args.photoProf)
      }
      axios({
        url: args.url + (args.professionnel.id === undefined ? '' : args.professionnel.id),
        method: 'post',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: bodyFormData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteProfessionnel ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/users/${id}`,
        method: 'delete',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        resolve(response)
        commit('deleteProfessionnel', id)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

const mutations = {
  setListeProfessionnels: (state, professionnels) => { state.professionnels = professionnels },
  setListeCategories: (state, categories) => { state.categories = categories },
  deleteProfessionnel: (state, idProfessionnel) => { state.professionnels = state.professionnels.filter(pro => pro.id !== parseInt(idProfessionnel)) }
}

export default {
  state,
  getters,
  actions,
  mutations
}
