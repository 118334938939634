var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"corppage"}},[_c('div',{staticClass:"topText"},[_c('h1',{staticClass:"h1"},[_vm._v("MES RENDEZ-VOUS")]),(_vm.siPeutMettreDispo)?_c('b-button',{staticClass:"btnAjouterDispo mt-2 ml-4 mr-4",attrs:{"size":"sm"},on:{"click":_vm.ajouterDispo}},[_vm._v("Créer disponibilité")]):_vm._e(),(_vm.siPeutMettreDispo)?_c('b-button',{staticClass:"btnAjouterVacances mt-2 ml-4 mr-4",attrs:{"size":"sm"},on:{"click":_vm.ajouterVacances}},[_vm._v("Créer Vacances")]):_vm._e(),_c('div',{attrs:{"id":"calendar"}},[_c('vue-scheduler',{ref:"calendrier",attrs:{"events":_vm.events,"labels":{
        today: 'Aujourd\'hui',
        back: 'Précédent',
        next: 'Suivant',
        month: 'Mois',
        week: 'Semaine',
        day: 'jour',
        all_day: 'heure'
      },"time-range":[0,23],"available-views":['week', 'day'],"initial-view":"week","event-display":_vm.eventDisplay,"disableDialog":true},on:{"event-clicked":_vm.eventClicked,"week-changed":_vm.weekChanged,"day-changed":_vm.dayChanged}})],1)],1),_c('b-modal',{attrs:{"id":"modal-rvDetail","centered":"","hide-footer":"","title":_vm.eventChoisiTitle}},[_c('ReservationDetail',{attrs:{"eventChoisi":_vm.eventChoisi,"eventChoisiType":_vm.eventChoisiType}})],1),_c('b-modal',{attrs:{"id":"modal-ajouterDispo","centered":"","hide-footer":"","title":"Ajouter disponibilité"}},[_c('DispoSelection')],1),_c('b-modal',{attrs:{"id":"modal-ajouterVacances","centered":"","hide-footer":"","title":"Ajouter Vacances"}},[_c('VacancesSelection')],1),_c('b-modal',{attrs:{"id":"modal-chargement","no-close-on-backdrop":true,"no-close-on-esc":true,"hide-header-close":true,"centered":"","hide-footer":"","title":"Chargement"}},[_vm._v(" Chargement... ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }